* {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-left: 0px;
  /* color: aliceblue; */
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}
li {
  list-style: none;
}
html,
body {
  overflow-x: hidden;
}

/* Navbar Starts Here */

.navbar-nav .nav-link {
  color: rgba(29, 29, 31, 0.7) !important;
  font-size: 17px;
  font-family: "Open Sans", sans-serif;
  padding: 0 10px;
  padding: 8px 23px;
  transition: 0.2s;
}
.nav-link.active {
  font-weight: 700;
  color: black !important;
}
.n-button .nav-link.active {
  font-weight: 600;
  color: white !important;
}
.navbar-nav .nav-link:hover {
  color: #000 !important;
}

.navbar {
  margin-top: 0px;
  padding-top: 0px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: 0 1px 40px 0 rgb(33 49 87 / 20%);
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: white;
  /* padding: 40px auto; */
}
.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.navbar-brand {
  font-weight: 400;
  font-size: 1.5em;
  color: black !important;
  letter-spacing: 2.5px;
  transition: 0.5s;
  /* height: 1em; */
}
.navbar-brand .beta {
  /* font-family: 'Montserrat Subrayada', sans-serif; */
  font-weight: 700;
  font-size: 35px;
}
.navbar-brand span {
  font-weight: 700;
}
.n-middle {
  align-items: center;
  text-align: center;
  margin: auto;
}
.n-middle .nav-item {
  padding-right: 20px;
}

.n-button a {
  text-decoration: none;
  background-color: #1d1d21;
  color: white;
  padding: 7px 15px;
  border-radius: 3px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.n-button a:hover {
  background-color: #000;
  color: white;
}

.scroll {
  background-color: #1d1929 !important;
  box-shadow: none !important;
  transition: none !important;
}
.scroll .navbar-brand {
  color: white !important;
  transition: none !important;
}
.scroll .navbar-nav .nav-link {
  color: white !important;
}
.scroll .navbar-light .navbar-nav .nav-link {
  color: white !important;
}
.scroll .n-button a {
  background-color: white !important;
  color: black !important;
}
.scroll-g {
  background-color: #e2ffe9 !important;
  box-shadow: none !important;
}

/* 
  .scrolled {
    background-color: rgb(42, 42, 42) !important;
    color: white !important;
    box-shadow: 0 1px 40px 0 rgb(33 49 87 / 20%);
    transition: 0.5s;
  }
  
  .scrolled .navbar-brand {
    color: white !important;
    transition: 0.5s ease-in-out;
  }
  .scrolled .navbar-brand .eta {
    display: none;
  }
  .scrolled .navbar-brand .ue {
    display: none;
  }
  .scrolled .navbar-brand .q {
    font-weight: 700;
    font-size: 24px;
    transition: 1s;
  }
  .scrolled .navbar-brand {
    font-weight: 700;
  }
  .scrolled .n-button a {
    background-color: white !important;
    color: black !important;
    transition: 0.5s ease-in-out;
    border-radius: 30px;
  }
  .scrolled .navbar-nav .nav-link {
    padding: 0 10px;
    border-radius: 30px;
    color: rgb(235, 233, 233) !important;
    border-radius: 30px;
    background: #292828;
    border: none solid #282727;
    box-shadow: 2px 2px 5px inset rgba(173, 172, 172, 0.2),
      -5px -5px 20px rgba(231, 227, 227, 0.2),
      25px 15px 15px inset rgba(0, 0, 0, 0.2), 25px 15px 15px rgba(0, 0, 0, 0.2);
    padding: 6px 20px;
    transition: 0.5s;
  } */

@media (min-width: 200px) and (max-width: 770px) {
  .navbar .container-fluid {
    justify-content: space-between;
  }
  .navbar-collapse {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .n-button a {
    background-color: white;
    color: black;
    padding: 9px 0px;
    margin-right: 16px;
  }

  /* .scrolled {
      background-color: white !important;
      color: black !important;
    }
  
    .scrolled .navbar-brand {
      color: black !important;
    }
    .scrolled .n-button a {
      background-color: white;
      color: black !important;
      transition: 0.5s ease-in-out;
    }
    .scrolled .navbar-nav .nav-link {
      padding: 0 10px;
      border-radius: 30px;
      color: black !important;
      border-radius: 30px;
      box-shadow: none;
      background: none;
      border: none;
      padding: 8px 23px;
      transition: 0.5s;
    }
    .scrolled .navbar-brand .eta {
      display: inline-block;
    }
    .scrolled .navbar-brand .ue {
      display: inline-block;
    }
    .scrolled .navbar-brand .q {
      font-weight: 700;
      font-size: 24px;
    } */
}

@media screen and (max-width: 770px) {
  .navbar {
    padding: 0;
  }
  .navbar .container-fluid {
    justify-content: space-between;
  }
}

/******************************/
/****** Navbar Ends Here *****/
/*****************************/

/******************************/
/****** Header Starts Here *****/
/*****************************/
.section1 {
  margin-top: 200px;
  padding-bottom: 50px;
}
.section1 .container {
  max-width: 80%;
}
.p-title {
  max-width: 500px;
}
.content {
  transform: translate(70px, -30px);
  margin-top: 65px;
  width: 85%;
}
.content h1 {
  font-size: 60px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: -1.5px;
  font-weight: 800;
  line-height: 63px;
  padding-bottom: 15px;
}
.content p {
  font-family: "Open Sans", sans-serif;
  padding-bottom: 35px;
  max-width: 450px;
  min-width: auto;
  font-size: 20px;
  color: #2c2c3f;
}
.content a {
  background-color: #1d1d21;
  color: white;
  padding: 14px 30px;
  border-radius: 3px;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}
.content a:hover {
  background-color: #000;
}
.section .container {
  max-width: 70%;
}

.img {
  position: relative;
  transform: translate(-20px, -70px);
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.img .animated {
  animation-name: floating;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  animation-timing-function: ease-in-out;
}

.map-btn {
  background-color: rgba(0, 0, 0, 0.3);
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 15px);
  }
  to {
    transform: translate(0, 0px);
  }
}

@media screen and (max-width: 479px) {
  .img .animated {
    display: none;
  }
  .section1 {
    margin-top: 100px;
    /* height: 67vh; */
  }
  .section1 .c-max {
    width: 100%;
    text-align: center;
    padding: 0;
  }
  .content a {
    font-size: 1rem;
    padding: 14px 20px;
  }
  .content h1 {
    font-size: 34px;
    line-height: 40px;
    text-align: center;
  }
  .c-max .content {
    align-items: center;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .content p {
    text-align: center;
    max-width: none;
    font-size: 18px;
  }
  .content a {
    align-items: center;
    align-self: auto;
  }
}

@media (min-width: 480px) and (max-width: 770px) {
  .img .animated {
    display: none;
  }
  .section1 {
    margin-top: 100px;
  }
  .section1 .c-max {
    width: 100%;
    text-align: center;
    padding: 0;
  }
  .content h1 {
    font-size: 34px;
    line-height: 40px;
    text-align: center;
  }
  .c-max .content {
    align-items: center;
    left: 0;
    transform: translate(0, 0);
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .content p {
    text-align: center;
    max-width: none;
    font-size: 18px;
  }
  .content a {
    align-items: center;
    align-self: auto;
  }
}

/******************************/
/****** Header Ends Here *****/
/*****************************/

/* Section 2 */
.sec-2 {
  padding-bottom: 100px;
}
.sec-2 .container {
  max-width: 80%;
  background-color: transparent;
  transition: all 250ms ease;
}
.sec-2 .heading {
  text-align: center;
  padding: 3em 0;
}
.sec-2 .heading span {
  font-weight: 100;
}
.sec-2 .heading h1 {
  font-size: 4em;
  padding-bottom: 30px;
  font-weight: 400;
}
.sec-2 .heading h1 span {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}
.sec-2 .heading p {
  font-family: "Open Sans", sans-serif;
}
.carousel-out {
  padding-top: 10px;
  transition: all 250ms ease;
}
.c-right .active {
  background-color: #fff;
  box-shadow: 6px 6px 30px 0 rgb(0 0 0 / 20%);
  padding: 10px 20px;
  border-radius: 15px;
}
.carousel-topic {
  display: flex;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 15px;
  transition: all 250ms ease;
}
.carousel-out:hover {
  box-shadow: 6px 6px 30px 0 rgb(0 0 0 / 20%);
  cursor: pointer;
}
.carousel-topic .solutions a {
  text-decoration: none;
  color: #000;
}
.carousel-topic :hover > h4 {
  color: #35bc98;
  cursor: pointer;
}
.carousel-topic i {
  padding-right: 15px;
  padding-top: 9px;
  transform: scale(20px, 20px);
}
.feature-bg-color {
  display: none;
  position: absolute;
  left: 10%;
  top: 158%;
  right: auto;
  bottom: 0%;
  z-index: -1;
  width: 260px;
  height: 410px;
  border-radius: 45px 10px 25px;
  background-color: #0094d6;
}
.feature-bg-color .green-light {
  background-color: #6ceecb;
}
.feature-bg-color .blue-dark {
  background-color: darkblue;
}
.feature-bg-color .green-dark {
  background-color: darkgreen;
}
.feature-img {
  position: relative;
  display: block;
  width: 45%;
  min-height: auto;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex: 0 0 auto;
}
.feature-img img {
  width: 500px;
  filter: drop-shadow(10px 10px 5px rgba(75, 75, 75, 0.3));
}
.d-none {
  display: none;
}
.d-block {
  display: block;
  transition: all 250ms ease;
}

@media screen and (max-width: 479px) {
  .sec-2 {
    padding-bottom: 0px !important;
  }
  .sec-2 .container {
    max-width: 100%;
  }
  .c-left {
    display: none;
    width: 0;
  }
  .sec-2 .row > .col-6:first-child {
    width: 0;
  }
  .sec-2 .row > .col-6 {
    width: 100%;
  }
}

@media screen and (max-width: 770px) {
  .sec-2 .container {
    max-width: 100%;
  }
  .c-left {
    display: none;
    width: 0;
  }
  .sec-2 .row > .col-6:first-child {
    width: 0;
  }
  .sec-2 .row > .col-6 {
    width: 100%;
  }
}
@media screen and (max-width: 1270px) {
  .sec-2 .container {
    max-width: 100%;
  }
  .feature-img img {
    width: 420px;
  }
}
/******************************/
/****** Section 2 Ends Here *****/
/*****************************/

/******************************/
/****** Why Choose Us Starts Here *****/
/*****************************/

.new-element {
  position: relative;
  display: flex;
  flex-direction: column;
}
.blue-block {
  background-image: url("../../public/img/dots.svg"),
    url("../../public/img/space-cloud.svg");
  background-position: top;
  background-repeat: repeat;
  background-color: #1d1929;
  background-size: contain;
  height: 100%;
  width: 100%;
  backdrop-filter: opacity(0.5);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  /* background-size: 1000px 687px; */
}

.why-choose-index {
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
  z-index: 5;
  margin-top: auto;
  margin-bottom: auto;
  color: white;
}
.whychoose-container {
  width: 75vw;
  margin: auto;
}
.whychoose-row {
  display: flex;
  justify-content: space-evenly;
}
.whychoose-col-1 {
  margin-top: auto;
  margin-bottom: auto;
}

.whychoose-heading {
  width: 80%;
}
.whychoose-heading h2 {
  font-size: 40px;
  font-weight: 700;
}
.whychoose-content {
  width: 85%;
  margin-bottom: 50px;
}
.whychoose-content p {
  font-weight: 500;
  letter-spacing: 1.2px;
  margin-top: 20px;
}
.whychoose-btn a {
  color: white;
  background-color: #1d1929;
  padding: 15px 30px;
  margin-top: 20px;
  font-weight: 700;
  border-radius: 8px;
  transition: all 0.4s;
  box-shadow: 2px 2px 5px inset rgb(173 172 172 / 20%),
    -5px -5px 20px rgb(231 227 227 / 20%), 25px 15px 15px inset rgb(0 0 0 / 20%),
    25px 15px 15px rgb(0 0 0 / 20%);
}
.whychoose-btn a:hover {
  box-shadow: 2px 2px 5px inset rgb(173 172 172 / 10%),
    -5px -5px 20px rgb(231 227 227 / 10%), 25px 15px 15px inset rgb(0 0 0 / 40%),
    25px 15px 15px rgb(0 0 0 / 40%);
}
.whychoose-col-2 img {
  height: 300px;
}

/******************************/
/****** Why Choose Us Ends Here *****/
/*****************************/

section.bg-color {
  position: relative;
}
.green-bg {
  background-color: #e2ffe9;
  display: flex;
  flex-direction: reverse;
  z-index: 1;
}
.green-bg .container {
  max-width: 1100px;
}
.partner-heading {
  text-align: center;
  margin-top: 80px;
}
.partner-heading h4 {
  font-size: 50px;
}
.partner-logo {
  width: 100%;
  padding-top: 60px;
}
.partner-logo ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.partner-logo ul li img {
  z-index: 10;
  /* height: 250px;
    width: 350px; */
  height: 150px;
  width: 250px;
  padding: 30px 30px;
  filter: grayscale(100%);
}
.bg-color .lower-svg svg {
  position: absolute;
  bottom: 0;
  height: 68px;
  width: 100%;
}
.blue-block .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1d1929;
  opacity: 0.85;
}
.index-g-svg {
  transform: translateY(-40px);
}
.index-m-svg {
  display: none;
}

@media screen and (max-width: 479px) {
  .partner-logo ul {
    justify-content: center;
  }
  .partner-logo ul li img {
    z-index: 10;
    height: 150px;
    width: 250px;
    padding: 10px 10px;
    filter: grayscale(100%);
  }
  .index-g-svg {
    transform: translateY(0px);
  }
  .whychoose-row {
    justify-content: center;
  }
  .whychoose-container {
    text-align: center;
  }
  .whychoose-heading,
  .whychoose-content {
    width: 100%;
  }
  .whychoose-heading h2 {
    font-size: 30px;
  }
  .whychoose-content p {
    font-size: 12px;
  }
  .whychoose-btn {
    margin: auto;
  }
  .whychoose-col-2 img {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 770px) {
  .whychoose-col-2 {
    align-items: center;
    padding-top: 50px;
  }
  .whychoose-col-2 img {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    height: 300px;
    transform: translateX(10%);
  }
  .whychoose-row {
    flex-direction: column;
  }
}

/******************************/
/****** Section 3 Starts Here *****/
/*****************************/
/* 
  .sec-3 {
    padding: 100px 0;
  }
  .sec-3 h2 {
    padding-bottom: 50px;
  }
  .clients {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .sec-3 h2 {
    margin: auto;
    text-align: center;
  }
  .c-logos {
    display: flex;
    width: 80%;
    margin: 33px auto 45px;
    padding: 0px;
    justify-content: space-around;
    align-items: center;
    border-radius: 7px;
    background-color: transparent;
    text-align: center;
  }
  
  .c-logos img {
    position: relative;
  }
  
  @media screen and (max-width: 479px) {
    .sec-3 {
      padding-top: 40px;
      padding-bottom: 0px;
    }
    .sec-3 h2 {
      font-size: 30px;
    }
    .c-logos {
      display: grid;
      max-width: 100%;
      margin-top: 0px;
      margin-bottom: 0px;
      padding: 10px;
      justify-items: center;
      align-content: flex-start;
      grid-auto-flow: row;
      grid-auto-columns: 1fr;
      grid-column-gap: 8px;
      grid-row-gap: 15px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
    }
    .c-logos img {
      height: auto;
      width: 80%;
    }
    .carousel-control-prev-icon {
      display: none;
    }
  }
  @media screen and (max-width: 770px) {
    .sec-3 {
      padding-top: 40px;
      padding-bottom: 20px;
    }
    .sec-3 h2 {
      font-size: 30px;
    }
    .c-logos {
      display: grid;
      max-width: none;
      padding-top: 15px;
      padding-bottom: 15px;
      -ms-flex-pack: center;
      justify-content: center;
      justify-items: center;
      flex-wrap: wrap;
      grid-auto-columns: 1fr;
      grid-column-gap: 8px;
      grid-row-gap: 8px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto;
      text-align: left;
    }
    .c-logos img {
      height: auto;
      width: 80%;
    }
    .sec-3 .container {
      width: 100%;
    }
  } */

/*********************** Section-3 Ends Here ********************************  */

/******************************/
/****** Section 4 starts Here *****/
/*****************************/
/* .sec-4 {
    padding-bottom: 120px;
  }
  .carousel {
    width: 85%;
    margin: auto;
    border-radius: 45px 10px;
    height: 100%;
    align-items: stretch;
    display: flex;
  }
  .carousel-inner {
    border-radius: 45px 10px;
  }
  
  .carousel-item {
    height: 100%;
  
    flex: 0 auto;
    align-items: stretch;
  }
  .testimonial-1 {
    background-image: linear-gradient(
      270deg,
      #5850ec,
      #5850ec 34%,
      #4f48cd 0,
      #4f48cd
    );
  }
  
  .testimonial-2 {
    background-image: linear-gradient(
      270deg,
      #5921cf,
      #5921cf 34%,
      #5126ad 0,
      #5126ad
    );
  }
  
  .testimonial-3 {
    background-image: linear-gradient(
      270deg,
      #19a3ac,
      #19a3ac 34%,
      #158a92 0,
      #158a92
    );
  }
  
  .testimonials {
    align-items: center;
  }
  
  .c-content {
    padding: 30px 0;
  }
  
  .c-content .col-4 {
    display: flex;
    height: 100%;
    min-height: 100%;
    padding-right: 59px;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
  .b-img img {
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    height: 100%;
  }
  
  .carousel-control-prev {
    display: flex;
    justify-content: space-around;
  }
  
  .carousel-control-next {
    display: flex;
    justify-content: space-around;
    padding-left: 55px;
  }
  
  .c-content .text {
    max-width: 80%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin: auto;
  }
  
  .c-content .text p {
    color: rgba(255, 255, 255, 0.85);
    font-size: 22px;
    line-height: 35px;
  }
  
  @media screen and (max-width: 479px) {
    .carousel {
      width: 100%;
      margin: 0;
    }
    .c-content .text p {
      font-size: 20px;
      line-height: 27px;
    }
    .c-title {
      display: flex;
    }
    .c-content .text {
      max-width: 80%;
      margin: auto;
    }
    .c-content .col-4 {
      display: none;
      width: 0;
    }
    .c-content .col-8 {
      width: 100%;
    }
  
    .carousel-item {
      height: 100%;
      flex: 0 auto;
      align-items: stretch;
    }
  
    .testimonial-1 {
      background-image: linear-gradient(270deg, #5850ec, #4f48cd);
    }
  
    .testimonial-2 {
      background-image: linear-gradient(270deg, #5126ad 0, #5126ad);
    }
  
    .testimonial-3 {
      background-image: linear-gradient(270deg, #158a92 0, #158a92);
    }
  }
  
  @media screen and (max-width: 770px) {
    .sec-4 .container {
      max-width: 730px;
    }
    .carousel {
      width: 100%;
      margin: 0;
    }
    .c-content .text p {
      font-size: 20px;
      line-height: 27px;
    }
    .c-title {
      display: flex;
      justify-content: center;
    }
    .c-content .text {
      max-width: 80%;
      margin: auto;
    }
    .c-content .col-4 {
      display: none;
      width: 0;
    }
    .c-content .col-8 {
      width: 100%;
    }
  
    .carousel-item {
      height: 100%;
      flex: 0 auto;
      align-items: stretch;
    }
  
    .testimonial-1 {
      background-image: linear-gradient(270deg, #5850ec, #4f48cd);
    }
  
    .testimonial-2 {
      background-image: linear-gradient(270deg, #5126ad 0, #5126ad);
    }
  
    .testimonial-3 {
      background-image: linear-gradient(270deg, #158a92 0, #158a92);
    }
  } */
/************************ Section 4 Ends Here **********************/

/******************************/
/****** Contact Us Form Starts Here *****/
/*****************************/
.contact {
  display: flex;
  padding: 20px 30px;
  align-items: center;
  text-align: center;
  padding-top: 50px;
  padding-bottom: 150px;
  margin-top: 70px;
}
.contact-form {
  width: 80%;
  margin: auto;
}
.contact .container {
  box-shadow: 0 1px 40px 0 rgb(33 49 87 / 20%);
  border-radius: 30px;
  padding-bottom: 38px;
}
.c-title .espan {
  font-size: 50px;
  position: absolute;
  margin: auto;
  transform: translate(-190px, 5px);
  z-index: -1;
  color: rgb(235, 233, 233);
  font-weight: 500;
  font-size: 80px;
}
.contact h1 {
  padding: 50px 0px;
  font-size: 70px;
}
.form-row {
  display: flex;
  margin-right: -5px;
  margin-left: -5px;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}
.contact-form button {
  cursor: pointer;
  text-decoration: none;
  padding: 13px 33px;
  border-radius: 8px;
  background: #1d1d21;
  transition: 0.5s;
  text-decoration: none;
  font-size: 20px;
  color: #fff;
  box-shadow: -15px -15px 15px rgba(218, 218, 218, 0.2),
    10px 10px 15px rgba(0, 0, 0, 0.2);
}
.contact-form button:hover {
  background: #000;
}
.form-group {
  margin-bottom: 1rem;
}
.form-control:focus {
  box-shadow: none;
  border-color: #e65f78;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
    0 0 8px rgba(255, 255, 255, 0.6);
  outline: 0 none;
  background: transparent;
  transition: 0.5s;
}
label {
  align-items: flex-start;
  display: flex;
  font-family: "Open Sans", sans-serif;
  color: #1d1d21;
  font-weight: 600;
  padding-bottom: 5px;
}
.alert {
  position: fixed;
  margin: auto;
  left: 33vw;
  top: 50vh;
  bottom: 50vh;
  align-items: center;
  margin: auto;
  height: 30%;
  background: white;
  padding: 30px 30px;
}
.alert h2 {
  padding: 20px 0;
  font-size: 40px;
}
.alert-s-m {
  color: #35bc98;
  display: block !important;
}
.alert-f-m {
  color: #cb2025;
  display: block !important;
}
.alert p {
  color: #000000;
}
.alert-s-m {
  align-items: center;
  display: block !important;
}
.alert-s-m.active {
  display: block !important;
}
.alert-f-m.active {
  display: block !important;
}
.spinner {
  display: block !important;
  position: fixed;
  left: 49vw;
  top: 45vh;
  bottom: 50vh;
  /* background: black; */
}

.load-circle.active {
  display: block !important;
}
.load-circle {
  display: block !important;
  position: fixed;
  top: -10%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}
.load-circle:before,
.load-circle:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}
.load-circle:before {
  animation: ball1 1s infinite;
  background-color: #cb2025;
  box-shadow: 30px 0 0 #f8b334;
  margin-bottom: 10px;
}
.load-circle:after {
  animation: ball2 1s infinite;
  background-color: #00a096;
  box-shadow: 30px 0 0 #97bf0d;
}

.msg-muted {
  font-size: 10px;
  color: grey;
  text-align: center;
  margin: auto 6px;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }
  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }
  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f8b334;
  }
  50% {
    box-shadow: 0 0 0 #f8b334;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #f8b334;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #97bf0d;
  }
  50% {
    box-shadow: 0 0 0 #97bf0d;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }
  100% {
    box-shadow: 30px 0 0 #97bf0d;
    margin-top: 0;
  }
}

.loading-text-i {
  display: block !important;
  position: fixed;
  left: 35.5vw;
  top: 50vh;
  bottom: 50vh;
  color: white;
  font-size: 25px;
}
.f-overlay {
  /* opacity: 0; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  /* pointer-events: none; */
  opacity: 1;
  pointer-events: all;
}
.f-overlay.active {
  opacity: 1;
  pointer-events: all;
}
select:hover {
  cursor: pointer;
}
.input ::placeholder {
  color: white;
}

@media screen and (max-width: 479px) {
  .contact {
    margin-top: 20px !important;
  }
  .contact .container {
    padding: 0;
  }
  .contact-form {
    width: 100%;
    margin: 0;
  }
  .contact .container {
    box-shadow: none;
  }
  .contact h1 {
    font-size: 54px;
    font-weight: 700;
  }
  .form-row {
    display: block;
    width: 101%;
    margin: 0;
  }
  .form-row .col-6 {
    width: 100%;
  }
  .loading-text-i {
    display: block !important;
    position: fixed;
    left: 14vw;
    width: 74%;
  }
  .alert {
    height: 106px;
  }
  /* .form-control{
      width: 500px;
    } */
}
@media (min-width: 480px) and (max-width: 770px) {
  .contact {
    height: 146vh;
  }
  .contact .container {
    padding: 0;
  }
  .contact-form {
    width: 100%;
    margin: 0;
  }
  .contact .container {
    box-shadow: none;
  }
  .contact h1 {
    font-size: 54px;
    font-weight: 700;
  }
  .form-row {
    display: block;
    width: 101%;
    margin: 0;
  }
  .form-row .col-6 {
    width: 100%;
  }
  .loading-text-i {
    display: block !important;
    position: fixed;
    left: 14vw;
    width: 74%;
  }
  .alert {
    height: 90px;
  }
}
/******************************/
/****** Contact Form Ends Here *****/
/*****************************/

/******************************/
/****** Footer Starts Here *****/
/*****************************/

.footer {
  background-color: rgb(29, 29, 29);
}

.footer .p1 {
  font-family: unset;
  text-align: center;
  color: rgb(255, 248, 248);
  margin-top: auto;
  margin-bottom: auto;
}
.footer .p1 a {
  color: rgb(255, 255, 255);
  transition: all 0.15s;
  opacity: 0.3;
  font-style: unset;
}
.footer .p1 a:hover {
  color: #06a86d;
  opacity: 1;
}
.p1 h1 {
  font-size: 100px;
  margin-top: auto;
}
.footer .container-fluid {
  padding-top: 60px;
  padding-bottom: 20px;
  padding-left: 30px;
  color: white;
  max-width: 1100px;
  padding-right: 30px;
  padding-left: 30px;
}
.container-fluid .f-row {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 30px;
}
.container-fluid .footer-text,
.footer-block {
  text-align: center;
  padding-bottom: 10px;
  color: grey;
  font-size: 15px;
}
.footer-block a {
  color: grey;
  transition: all 0.15s;
}
.footer-block a:hover {
  color: #2adbcf;
}
.footer .nav-part {
  position: relative;
}

.nav-part .f-head {
  text-align: left;
  margin-bottom: 20px;
  color: grey;
  font-size: 14px;
  cursor: pointer;
}

.f-list {
  text-align: left;
  color: grey;
  cursor: pointer;
  font-size: 13px;
  margin-top: 35px;
}

.f-list .f-add {
  margin-top: 20px;
  width: 150px;
  cursor: default;
}
.f-list a {
  transition: all 0.25s;
  padding-left: 0;
  color: rgb(206, 205, 205);
}
.f-list a:hover {
  color: #2adbcf;
}
.footer .nav-part .f-head a {
  color: grey;
  transition: all 0.15s;
  font-size: 12px;
}
.footer .nav-part .f-head a:hover {
  color: #2adbcf;
}
.media-logo {
  padding-bottom: 2%;
  text-align: center;
  margin: auto 0;
}

.media-logo i:hover {
  transform: scale(1.2);
  color: #2adbcf;
}

.media-logo i {
  padding-left: 20px;
  font-size: 20px;
  position: relative;
  left: -10px;
  top: 10px;
  color: grey;
  transition: 0.3s;
}

.media-logo a {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: inline-block;
  position: relative;
}

@media screen and (max-width: 479px) {
  .p1 h1 {
    font-size: 40px;
  }
  .container-fluid .f-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  .nav-part .f-head {
    text-align: left;
  }
  .f-list {
    text-align: left;
    margin-top: 0px;
    margin-bottom: 40px;
  }
  .footer .nav-part .f-head a {
    box-shadow: none;
    background-color: transparent;
    padding: 0px;
  }
}
@media (min-width: 480px) and (max-width: 770px) {
  .media-logo {
    margin-left: 0;
    padding-bottom: 5%;
  }
  .container-fluid .f-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }
  .p1 .text-muted {
    margin-top: 9%;
    position: absolute;
  }
}
/******************************/
/****** Footer Ends Here *****/
/*****************************/

/******************************************************************************************************************************/
/************************************************* Carrers Page Starts Here ************************************************/
/******************************************************************************************************************************/

/******************************/
/****** Careers Sec1 Starts Here *****/
/*****************************/

.careers-sec-1 {
  min-height: 60vh;
  text-align: center;
  padding-bottom: 80px;
}
.careers-sec-1 .container {
  margin-top: 15%;
}
.careers-sec-1 .typing,
.section1 .typing {
  color: #35bc98;
}
.careers-sec-1 h3,
.section1 h3 {
  font-size: 3.6em;
  font-weight: 600;
}
.careers-sec-1 p {
  font-size: 23px;
  width: 60%;
  margin: auto;
  color: #1d1d1fd3;
  line-height: 1.2;
  padding-top: 40px;
  font-weight: 600;
}
.careers-sec-1 hr {
  position: relative;
  color: rgb(172, 168, 168);
  width: 10%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 80px;
}
.careers-sec-1 a {
  padding: 15px 25px;
  background-color: #1d1d21;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.careers-sec-1 a:hover {
  background-color: black;
}
@media screen and (max-width: 479px) {
  .careers-sec-1 {
    position: relative;
  }
  .careers-sec-1 .container {
    margin-top: 30%;
    position: relative;
  }
  .careers-sec-1 h3 {
    font-size: 2em;
  }
  .careers-sec-1 p {
    font-size: 18px;
    width: 100%;
    margin: auto;
  }
  .careers-sec-1 hr {
    margin-bottom: 40px;
  }
}
@media (min-width: 480px) and (max-width: 770px) {
  .careers-sec-1 {
    padding-bottom: 30px;
  }
  .careers-sec-1 p {
    font-size: 18px;
    width: 100%;
    margin: auto;
  }
}

/******************************/
/****** Careers Sec1 Ends Here *****/
/*****************************/

/******************************/
/****** Careers Sec2 Starts Here *****/
/*****************************/

.careers-sec-2 {
  text-align: center;
  position: relative;
  padding: 100px 0px;
}
.careers-sec-2 .careers-container {
  width: 100%;
  margin: auto;
  background-color: rgba(226, 255, 233, 1);
  position: relative;
  padding-top: 50px;
  padding-bottom: 150px;
  margin-bottom: 50px;
}
.careers-sec-2 .container {
  width: 80%;
  margin: auto;
}
.careers-sec-2 .careers-container h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 45px;
}
.careers-sec-2 p {
  width: 70%;
  margin: auto;
  margin-bottom: 50px;
}
.careers-sec-2 .perks-quote {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0;
  padding-top: 25px;
}
.careers-sec-2 .perks-container {
  margin-top: 6%;
  text-align: left;
  margin: auto;
  padding-top: 10%;
}
.perks-container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.perks-container .row {
  min-height: 70vh;
  align-items: center;
}
.perks-1-r {
  position: relative;
  transform: translateY(-16%);
}
.perks-container .perks-2-l {
  align-items: center;
}
.careers-sec-2 .perks-container h2 {
  width: 70%;
  margin-left: 14.5%;
  font-size: 46px;
  padding-bottom: 25px;
  line-height: 50px;
}
.careers-sec-2 .perks-container p {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: black;
}
.career-benefits {
  text-align: center;
  margin-top: 10%;
}
.career-benefits a {
  color: black;
}
.career-benefits a span {
  color: #35bc98;
  font-weight: 700;
}
.career-benefits h2 {
  margin: auto;
  margin-bottom: 20px;
}
.career-benefits p {
  color: black;
}

@media screen and (max-width: 479px) {
  .perks-container .row {
    min-height: 50vh;
  }
  .careers-sec-2 .perks-quote {
    margin: 0;
    width: 100%;
    font-size: 16px;
  }
  .careers-sec-2 .perks-container {
    text-align: left;
    margin: auto;
    padding-top: 45%;
  }
  .perks-container .col-6 {
    width: 100%;
    text-align: left;
    padding: 0;
  }
  .careers-sec-2 .perks-container h2 {
    margin: 0;
    width: 100%;
    font-size: 38px;
  }
  .perks-container p {
    margin: 0;
    width: 100%;
  }
  .perks-1-l {
    display: none;
  }
  .perks-2-r {
    display: none;
  }
  .careers-sec-2 .career-benefits h2 {
    width: 100%;
    margin: 0;
    font-size: 31px;
    line-height: 35px;
  }
}

@media (min-width: 480px) and (max-width: 770px) {
  .careers-sec-2 {
    padding-top: 10%;
  }
  .careers-sec-2 .perks-quote {
    margin: 0;
    width: 100%;
    font-size: 16px;
  }
  .careers-sec-2 .perks-container {
    text-align: left;
    margin: auto;
    padding-top: 25%;
  }
  .perks-container .col-6 {
    width: 100%;
    text-align: left;
    padding: 0;
  }
  .careers-sec-2 .perks-container h2 {
    margin: 0;
    width: 100%;
    font-size: 38px;
  }
  .perks-container p {
    margin: 0;
    width: 100%;
  }
  .perks-1-l {
    display: none;
  }
  .perks-2-r {
    display: none;
  }
  .careers-sec-2 .career-benefits h2 {
    width: 100%;
    margin: 0;
    font-size: 31px;
    line-height: 35px;
  }
  .openings {
    padding-top: 10%;
    width: 100% !important;
    margin: 0 !important;
  }
}

/******************************/
/****** Careers Sec2 Ends Here *****/
/*****************************/

/******************************/
/****** Careers Sec3 Starts Here *****/
/*****************************/

.careers-sec-3 {
  text-align: center;
  padding-bottom: 150px;
}
.careers-sec-3 h3 {
  margin-bottom: 20px;
  font-size: 50px;
}
.careers-sec-3 p {
  width: 60%;
  margin: auto;
}
.openings {
  width: 75%;
  margin: auto;
  margin-top: 5%;
}
.openings-name {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.openings-name a {
  background-color: #f9fafb;
  padding: 20px;
  border: 1px solid #cdcecf;
  color: black;
  transition: all 0.3s;
}
.openings-name a:hover {
  color: #35bc98;
  transition: none 2s;
}
.openings-name a i {
  transition: all 0.15s;
}
.openings-name a:hover > i {
  margin-left: 10px;
  transition: none 1s;
}

/********************************************************* Careers Section Ends Here ********************************************/

/******************************************************************************************************************************/
/************************************************* Blog Page Styling Starts Here ************************************************/
/******************************************************************************************************************************/

/******************************/
/****** Blogs Sec1 Starts Here *****/
/*****************************/
.blogs-sec-1 {
  min-height: 80vh;
  text-align: center;
}
.blogs-sec-1 .container {
  margin-top: 10%;
  display: flex;
}
.blogs-sec-1 .container .b-card {
  display: flex;
  width: 70%;
  min-height: 400px;
  height: 100%;
  flex-direction: row-reverse;
  border-radius: 20px;
  margin-top: 80px;
  transition: 0.15s ease-out;
  box-shadow: 0 4px 8px rgb(0 0 0 / 18%);
  border: 0.5px solid rgb(0 0 0 / 8%);
}
.blogs-sec-1 .container .b-card:hover {
  box-shadow: 0 23px 38px rgb(0 0 0 / 25%);
  cursor: pointer;
}
.blogs-sec-1 .container .b-blogs {
  width: 30%;
  position: relative;
  margin-top: 10%;
  padding-left: 30px;
}
.blogs-sec-1 .container .b-blogs hr {
  width: 30%;
  margin: auto;
  margin-bottom: 30px;
}
.b-blogs h2 {
  color: #35bc98;
  font-size: 50px;
}
.b-blogs p {
  font-size: 20px;
  padding-left: 10px;
  margin-bottom: 50px;
}
.b-blogs a {
  text-decoration: none;
  color: white;
  padding: 12px 25px;
  background-color: #1d1d21;
  border-radius: 4px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.b-blogs a:hover {
  background-color: black;
  cursor: pointer;
}
.b-img {
  position: relative;
  width: 45%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.blogs-sec-1 .container .b-card .b-img img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.b-card .c-body {
  align-items: center;
  padding: 20px 30px;
  text-align: left;
  position: relative;
}
.c-body .b-span {
  margin-right: 30px;
  color: black;
  font-weight: 400;
}
.c-body p {
  color: rgb(105, 104, 104);
}
.c-body p span {
  color: black;
  font-weight: 500;
}
.c-body aside {
  display: flex;
  margin-bottom: 15px;
}
.c-body a {
  position: absolute;
  bottom: 18%;
  text-decoration: none;
  color: rgb(107, 107, 107);
  font-size: 24px;
}
.c-body a i {
  color: black;
  transition: all 0.15s;
}
.c-body a:hover {
  color: black;
}
.c-body a:hover > i {
  margin-left: 10px;
}
.c-body h2 {
  text-align: left;
  margin-top: 0;
  margin-bottom: 15px;
  letter-spacing: -1px;
  line-height: 1;
  font-size: 60px;
  font-weight: 300;
}

/******************************/
/****** Blogs Sec2 Starts Here *****/
/*****************************/

.blogs-sec-2 {
  width: 85%;
  margin: 50px auto;
}
.blogs-sec-2 h1 {
  color: #585858;
  font-weight: 300;
  font-size: 60px;
  letter-spacing: 1.5px;
}

/******************************/
/****** Blogs Sec3 Starts Here *****/
/*****************************/

.blogs-sec-3 h1 {
  color: #585858;
  font-weight: 300;
  font-size: 60px;
  letter-spacing: 1.5px;
}
.blogs-sec-3 {
  width: 85%;
  margin: 50px auto;
  margin-top: 15%;
  padding-bottom: 20%;
}
.p-card {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-flow: wrap;
}
.card {
  max-width: 300px;
  min-height: 390px;
  border-radius: 20px;
  margin-top: 80px;
  transition: 0.15s ease-out;
  box-shadow: 0 4px 8px rgb(0 0 0 / 18%);
}
.card:hover {
  box-shadow: 0 23px 38px rgb(0 0 0 / 25%);
  cursor: pointer;
}
.card img {
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  flex-grow: 1;
}
.c-wrap-link {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 0;
}
.card-body h4 {
  font-size: 16px;
  color: #5d2bff;
}
.card-body h2 {
  font-size: 20px;
  font-weight: 300;
  padding-top: 10px;
}
.card-body p {
  position: absolute;
  bottom: 20px;
}

@media screen and (max-width: 479px) {
  .blogs-sec-1 .container {
    display: inline-block;
  }
  .blogs-sec-1 .container .b-card {
    width: 90%;
    display: inline-block;
    height: 500px;
  }
  .b-img {
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 0px;
  }
  .c-body h2 {
    font-size: 40px;
  }
  .c-body a {
    bottom: -5%;
  }
  .blogs-sec-1 .container .b-blogs {
    width: 100%;
    padding-left: 0;
    margin-top: 25%;
  }
  .card {
    min-width: 90%;
    margin: auto;
    margin-top: 25%;
  }
  .card-body {
    padding: 1.5rem 1.5rem;
  }
  .blogs-sec-2 {
    margin-top: 33%;
  }
  .blogs-sec-2 h1 {
    text-align: center;
  }
  .blogs-sec-3 {
    margin-top: 33%;
  }
  .blogs-sec-3 h1 {
    text-align: center;
  }
}

@media (min-width: 480px) and (max-width: 770px) {
  .blogs-sec-1 .container {
    display: inline-block;
  }
  .blogs-sec-1 .container .b-card {
    width: 75%;
    display: inline-block;
    max-height: 680px;
  }
  .b-img {
    width: 100%;
    height: 42vw;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 0px;
  }
  .c-body h2 {
    font-size: 40px;
    margin-bottom: 40px;
  }
  .c-body a {
    position: absolute;
    bottom: 10px;
  }
  .blogs-sec-1 .container .b-blogs {
    width: 100%;
    padding-left: 0;
    margin-top: 25%;
  }
  .card {
    min-width: 45%;
    margin: auto;
    margin-top: 12%;
  }
  .card-body {
    padding: 1.5rem 1.5rem;
    align-items: left;
    text-align: left;
  }
  .blogs-sec-2 {
    margin-top: 16%;
  }
  .blogs-sec-2 h1 {
    text-align: center;
  }
  .blogs-sec-3 {
    margin-top: 16%;
  }
  .blogs-sec-3 h1 {
    text-align: center;
  }
}
@media (min-width: 771px) and (max-width: 1200px) {
  .p-card {
    justify-content: inherit;
    margin-top: 10%;
  }
  .card {
    margin: 0 15px 3rem;
    width: 277px;
  }
  .blogs-sec-1 .container {
    display: inline-block;
    margin-top: 5%;
  }
  .blogs-sec-1 .container .b-card {
    width: 100%;
    max-height: 680px;
  }
  .blogs-sec-1 .container .b-blogs {
    width: 100%;
    padding-left: 0;
    margin-top: 15%;
  }
  .c-body h2 {
    font-size: 45px;
    margin-bottom: 40px;
  }
  .c-body a {
    left: 9%;
    bottom: 20px;
  }
  .blogs-sec-2 {
    margin-top: 18%;
    width: 90%;
  }
  .blogs-sec-3 {
    margin-top: 18%;
    width: 90%;
  }
}

/*******************************************************************************************************************************/
/****************************************************** Blogs template Section Start Here ***************************************/
/*******************************************************************************************************************************/

.temp-sec-1 {
  margin-top: 15%;
}
.temp-sec-1 .container {
  width: 50%;
  margin: auto;
}
.temp-sec-1 img {
  width: 100%;
  height: 100%;
  padding-bottom: 10%;
}
.temp-sec-1 .b-date {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 4px;
  text-align: center;
  margin-bottom: 30px;
}
.b-head {
  text-align: center;
}
.b-heading {
  font-size: 60px;
  font-weight: 400;
}
.b-head .sub {
  padding-top: 20px;
}
.b-head .sub span {
  color: #35bc98;
  font-size: 30px;
  padding: 10px;
}

.b-content {
  margin-top: 12%;
}
.b-content img {
  margin-top: 10%;
  transition: all 0.4s;
}
.b-content img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

/******************************/
/****** Blogs Tem sec2 Starts Here *****/
/*****************************/

.temp-sec-2 {
  margin: 15% 0;
  text-align: center;
}
.temp-sec-2 .container {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.temp-sec-2 .b-heading {
  margin-right: 50px;
  transform: translateX(-40%);
}

.temp-card {
  display: flex;
  flex-direction: row;
}
.temp-card .card {
  width: 280px;
  margin: 0 15px 3rem;
  text-align: left;
}
.temp-card .card img {
  height: 100px;
}

@media screen and (max-width: 479px) {
  .temp-sec-1 .container {
    width: 90% !important;
  }
  .temp-sec-1 .b-date {
    font-size: 13px;
  }
  .b-head .b-heading {
    font-size: 36px;
  }
  .b-head .sub span {
    font-size: 20px;
  }
  .temp-card {
    display: inline-block;
  }
  .temp-sec-2 .b-heading {
    margin-right: 0;
    transform: translateX(0);
    padding-bottom: 10%;
    font-size: 50px;
    font-weight: 300;
  }
  .temp-sec-2 .container {
    display: inline-block;
    margin: auto;
  }
}

@media (min-width: 480px) and (max-width: 770px) {
  .temp-sec-1 .container {
    width: 100%;
  }
  .temp-sec-1 .b-date {
    font-size: 13px;
  }
  .b-head .b-heading {
    font-size: 36px;
  }
  .b-head .sub span {
    font-size: 20px;
  }
  .temp-card {
    display: inline-block;
  }
  .temp-sec-2 .b-heading {
    margin-right: 0;
    transform: translateX(0);
    padding-bottom: 10%;
    font-size: 50px;
    font-weight: 300;
  }
  .temp-sec-2 .container {
    display: inline-block;
    margin: auto;
  }
}

@media (min-width: 771px) and (max-width: 1200px) {
  .temp-sec-1 .container {
    width: 60%;
  }
  .temp-sec-2 .container {
    display: inline-block;
    margin: auto;
  }
  .temp-sec-2 .b-heading {
    font-size: 50px;
    font-weight: 300;
    transform: translateX(0);
    padding-bottom: 7%;
  }
}

/*******************************************************************************************************************************/
/****************************************************** Careers template Section Start Here ***************************************/
/*******************************************************************************************************************************/

.c-temp {
  min-height: 100vh;
  padding-top: 150px;
  text-align: center;
  margin-bottom: 10%;
}
.c-temp .container h3 {
  color: #35bc98;
  font-weight: 500;
  font-size: 50px;
}
.c-temp .container hr {
  width: 30%;
  margin: auto;
  color: rgb(131, 131, 131);
  margin-top: 0px;
  margin-bottom: 20px;
}
.c-temp .container .position-name {
  font-size: 30px;
  margin-bottom: 60px;
  font-weight: 700;
}
.c-temp .container .career-info {
  width: 50%;
  margin: auto;
  text-align: left;
  padding-bottom: 6%;
}
.career-info .career-desc h4 {
  padding-bottom: 10px;
}
.career-info .career-tech {
  display: inline-block;
}
.career-info .career-tech ul li {
  list-style-type: disc;
}
.career-info .career-reqmt h4 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.career-info .career-reqmt ul li {
  list-style-type: disc;
}
.career-info .career-benefits {
  text-align: left;
  margin-top: 0px;
}
.career-info .career-benefits h4 {
  padding-top: 30px;
  padding-bottom: 10px;
}
.career-info .career-benefits ul li {
  list-style-type: disc;
}
.c-temp .container .job-apply {
  padding: 15px 25px;
  background-color: #1d1d21;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

@media screen and (max-width: 479px) {
  .c-temp .container .career-info {
    width: 90%;
    margin-bottom: 10%;
  }
  .c-temp .container hr {
    width: 90%;
  }
  .c-temp {
    margin-bottom: 25%;
  }
}
@media (min-width: 480px) and (max-width: 770px) {
  .c-temp .container .career-info {
    width: 100%;
    margin: 0;
    margin-bottom: 10%;
  }
  .c-temp .container {
    width: 100%;
    margin-left: 30px;
    margin-right: 0px;
  }
  .c-temp .container hr {
    width: 70%;
  }
  .c-temp {
    margin-bottom: 25%;
  }
  .career-info .career-desc {
    margin: 0;
    width: 85vw;
  }
}
@media (min-width: 771px) and (max-width: 1200px) {
  .c-temp .container .career-info {
    width: 100%;
    margin: 0;
    margin-bottom: 4%;
  }
  .c-temp .container {
    width: 70%;
  }
  .c-temp .container hr {
    width: 70%;
  }
}

/*******************************************************************************************************************************/
/****************************************************** Services Page Start Here ***************************************/
/*******************************************************************************************************************************/
.services-sec-1 {
  text-align: center;
  position: relative;
}
.services-sec-1 .container {
  margin-top: 12%;
}
.services-sec-1 .container h3 {
  font-size: 75px;
  color: #35bc98;
}

.about-content img {
  padding-top: 0;
}
.about-content .img {
  transform: translate(-30%, -10%);
}
.about-right {
  transform: translateX(15%);
}
.img img {
  transform: translateX(-50%);
}
/* .bg-img{
  background: linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url(/img/service.jpg);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center center;
  background-size: 100%;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  color:rgb(42, 42, 42);
  } */

.services-sec2 {
  text-align: center;
  margin-top: 14vh;
  padding-bottom: 120px;
  transition: ease-in 0.2s;
}
.scroll {
  background-color: rgba(233, 227, 227, 1);
  transition: ease-in 0.2s;
}
.services-sec2 h1 {
  font-size: 3rem;
  font-family: "Nunito", sans-serif;
}
.service-blocks {
  display: grid;
  grid-column-gap: 10vw;
  grid-template-columns: auto auto;
  justify-content: center;
  align-content: center;
  text-align: left;
  padding: 50px 50px;
  min-height: 70vh;
  align-items: center;
  text-align: center;
  margin: auto;

  padding: 50px 50px;
}
.service-blocks .service-img {
  width: 35vw !important;
}

/* Service Header element*/

.service_header {
  font-size: 80px;
  font-weight: 700;
  line-height: 100px;
}

/* Service Paragraph element*/

.service_paragraph {
  margin-top: 10px;
  color: gray;
  font-weight: 600;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .services-sec-1 .container {
    margin-top: 54%;
  }
  .service_header {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    margin-bottom: 25px;
  }
  .services-sec-1 .container h3 {
    font-size: 35px;
    color: #35bc98;
  }
  .about-header h1 {
    font-size: 34px;
    line-height: 40px;
    margin: 0;
    width: 100%;
  }
  .about-content .img {
    width: 0;
  }
  .about-right {
    width: 100%;
    transform: translateX(0);
    padding-left: 10px;
  }
  .service-blocks {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0px 10px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .service-blocks:nth-child(odd) {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    flex-flow: reverse;
    padding: 0px 10px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .service-blocks img {
    padding-bottom: 20px;
  }
  .service-blocks .service-img {
    width: 70% !important;
  }
  .services-sec2 {
    position: relative;
    padding-bottom: 50px;
  }
  .services-sec2 h1 {
    font-size: 32px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .services-sec-1 .container {
    margin-top: 30%;
    width: 100%;
  }
  .service-blocks {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0px 10px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .service-blocks:nth-child(odd) {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    flex-flow: reverse;
    padding: 0px 10px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .service-blocks img {
    padding-bottom: 20px;
  }
  .about-header h1 {
    font-size: 34px;
    line-height: 40px;
    margin: 0;
    width: 100%;
  }
  .about-content .img {
    width: 0;
  }
  .about-right {
    width: 100%;
    transform: translateX(0);
    padding-left: 10px;
  }
  .services-sec2 {
    position: relative;
    margin-top: 80vh;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .service-blocks {
    grid-template-columns: auto;
    text-align: center;
    padding: 30px 30px;
  }
  .service-blocks .service-img {
    width: 70% !important;
    margin-bottom: 20px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 480px) and (max-width: 770px) {
  .service-blocks {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0px 10px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .service-blocks:nth-child(odd) {
    display: flex;
    flex-direction: column-reverse;
    text-align: center;
    flex-flow: reverse;
    padding: 0px 10px;
    padding-bottom: 20px;
    margin-bottom: 30px;
  }
  .service-blocks img {
    padding-bottom: 20px;
  }
  .service-blocks .service-img {
    width: 70% !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .service-blocks {
    grid-template-columns: auto auto;
    text-align: left;
    padding: 50px 50px;
  }
  .service-blocks .service-img {
    width: 35vw !important;
  }
}

/*******************************************************************************************************************************/
/****************************************************** Why Us Page Start Here ***************************************/
/*******************************************************************************************************************************/

.why-us-sec-1 {
  text-align: center;
  padding-bottom: 90px;
}
.why-us-sec-1 .container {
  margin-top: 11%;
  text-align: center;
}
.why-us-sec-1 h3 {
  font-size: 55px;
  font-weight: 700;
  width: 80%;
  text-align: center;
  margin: auto;
}
.why-us-sec-1 p {
  font-size: 26px;
  width: 60%;
  margin: auto;
  color: #1d1d1fd3;
  line-height: 1.2;
  padding-top: 65px;
  font-weight: 600;
}
.why-us-sec-1 hr {
  position: relative;
  color: rgb(172, 168, 168);
  width: 10%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  margin-bottom: 80px;
}
.why-us-sec-1 a {
  padding: 15px 25px;
  background-color: #1d1d21;
  color: white;
  border-radius: 4px;
  font-size: 18px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.why-us-sec-1 a:hover {
  background-color: black;
}
@media screen and (max-width: 479px) {
  .why-us-sec-1 {
    padding-bottom: 40px;
  }
  .why-us-sec-1 .container {
    margin-top: 30%;
  }
  .why-us-sec-1 h3 {
    font-size: 2em;
  }
  .why-us-sec-1 p {
    font-size: 18px;
    width: 100%;
    margin: auto;
  }
}
@media (min-width: 480px) and (max-width: 770px) {
  .why-us-sec-1 p {
    font-size: 18px;
    width: 100%;
    margin: auto;
  }
}

/******************************/
/****** WhyUs sec2 Starts Here *****/
/*****************************/

.why-us-sec-2 {
  text-align: center;
}
.why-us-sec-2 .container {
  width: 80%;
}
.why-us-sec-2 p {
  width: 70%;
  margin: auto;
  margin-bottom: 50px;
}
.why-us-sec-2 .info-container {
  /* margin-top: 6%; */
  text-align: left;
  margin: auto;
}
.info-container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.info-container .row {
  min-height: 70vh;
  align-items: center;
}
.perks-container .info-2-l {
  align-items: center;
}
.why-us-sec-2 .info-container h2 {
  width: 70%;
  margin-left: 14.5%;
  font-size: 46px;
  padding-bottom: 25px;
  line-height: 50px;
}
.why-us-sec-2 .info-container p {
  margin-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  color: black;
}

@media screen and (max-width: 479px) {
  .info-container .row {
    min-height: 50vh;
  }
  .why-us-sec-2 .info-container {
    text-align: left;
    margin: auto;
    padding-top: 45%;
  }
  .info-container .col-6 {
    width: 100%;
    text-align: left;
    padding: 0;
  }
  .why-us-sec-2 .info-container h2 {
    margin: 0;
    width: 100%;
    font-size: 38px;
  }
  .info-container p {
    margin: 0;
    width: 100%;
  }
  .info-2-r {
    display: none;
  }
}

@media (min-width: 480px) and (max-width: 770px) {
  .why-us-sec-2 {
    padding-top: 10%;
  }
  .why-us-sec-2 .perks-container {
    text-align: left;
    margin: auto;
    padding-top: 25%;
  }
  .perks-container .col-6 {
    width: 100%;
    text-align: left;
    padding: 0;
  }
  .why-us-sec-2 .perks-container h2 {
    margin: 0;
    width: 100%;
    font-size: 38px;
  }
  .perks-container p {
    margin: 0;
    width: 100%;
  }
  .perks-1-l {
    display: none;
  }
  .perks-2-r {
    display: none;
  }
  .why-us-sec-2 .why-us-benefits h2 {
    width: 100%;
    margin: 0;
    font-size: 31px;
    line-height: 35px;
  }
  .openings {
    padding-top: 10%;
    width: 100% !important;
    margin: 0 !important;
  }
}

/******************************/
/****** Why Us sec3 Starts Here *****/
/*****************************/

.why-us-sec-3 {
  margin-top: 100px;
  position: relative;
}
.why-us-sec-3 .why-us-container {
  background-color: rgba(226, 255, 233, 1);
}
.why-us-container .why-sec3-heading {
  text-align: center;
  max-width: 900px;
  margin: auto;
  padding-top: 50px;
}
.why-us-container .container {
  padding-bottom: 100px;
}
.why-sec3-heading h2 {
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 50px;
}
.why-sec3-heading p {
  font-size: 25px;
  font-weight: 500;
}

/******************************/
/****** Why Us FAQ sec Starts Here *****/
/*****************************/
.faq {
  width: 100%;
}
.faq .faq-subheading {
  text-align: center;
  margin-bottom: 50px;
}
.faq-subheading h2 {
  font-size: 65px;
  font-weight: 600;
  color: #fdfdfd;
}
.faq .flap {
  width: 1000px;
  margin: auto;
  margin-top: 80px;
}
.careers-sec-2 .flap {
  display: flex;
  justify-content: center;
}
.flap-data .content-box {
  position: relative;
  margin: 10px;
  margin: 10px 50px;
  border: 1px solid rgba(102, 99, 99, 0.4);
  border-radius: 8px;
  padding: 15px 20px;
}

.flap-data .content-box:hover {
  border: 1px solid rgb(109 61 222 / 100%);
}
.flap-data .content-box .label {
  position: relative;
  padding: 10px;
  background-color: transparent;
  color: black;
  cursor: pointer;
  font-size: 20px;
  transition: transform 1.4s ease-in;
  font-weight: 700;
}
.content-box .label p {
  width: 90%;
  margin: 0px auto;
}
.flap-data .content-box .label::before {
  content: "+";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  font-size: 1.5em;
  transform: rotate(90deg);
  transition: transform 0.2s ease-in;
}
.flap-data .content-box.active {
  border: 1px solid rgb(109 61 222 / 100%);
}
.flap-data .content-box.active .label::before {
  content: "-";
  transform: rotate(180deg);
  transition: transform 0.2s ease-in;
}
.flap-data .content-box .content1 {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1.4s ease-in-out;
}

.flap-data .content-box .content1 p {
  padding-top: 10px;
  padding-left: 10px;
}
.flap-data .content-box.active .content1 {
  max-height: 300px;
  animation: fade 1s ease-in-out;
}

.flap-data .content-box .content-c {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 1.4s ease-in-out;
}

.careers-sec-2 .flap-data .content-box .content-c p {
  padding-left: 10px;
  margin-bottom: 5px;
  margin-top: 0px;
}

.flap-data .content-box .content {
  position: relative;
  max-height: 300px;
  overflow: hidden;
  transition: max-height 1.4s ease-in-out;
}
.flap-data .content-box .content1 p {
  padding-top: 10px;
  padding-left: 10px;
}

.flap-data .content-box .content-c p {
  padding-top: 10px;
  padding-left: 10px;
}
.flap-data .content-box.active .content-c {
  max-height: 300px;
  animation: fade 1s ease-in-out;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media screen and (max-width: 479px) {
  .faq .flap {
    display: flex;
    flex-direction: column;
  }
  .careers-sec-2 .flap {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    padding-top: 50px;
  }
  .careers-sec-2 {
    padding: 50px 0px;
    margin-top: 0px;
  }
  .careers-sec-2 .careers-container {
    padding-bottom: 50px;
  }
  .flap-data .content-box .label {
    font-size: 17px;
  }
}

@media screen and (max-width: 479px) {
  .faq .flap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .why-sec3-heading h2 {
    font-size: 30px;
    font-weight: 700;
    padding-top: 50px;
    margin-bottom: 50px;
  }
  .why-sec3-heading p {
    font-size: 15px;
    font-weight: 500;
  }
  .flap-data .content-box {
    position: relative;
    margin: 10px;
    border: 1px solid rgba(102, 99, 99, 0.4);
    border-radius: 8px;
    padding: 15px 20px;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .faq .flap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .careers-sec-2 {
    padding-bottom: 50px;
  }
  .careers-sec-2 .flap {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    padding-top: 50px;
  }
  .why-sec3-heading h2 {
    font-size: 30px;
    font-weight: 700;
    padding-top: 50px;
    margin-bottom: 50px;
  }
  .why-sec3-heading p {
    font-size: 15px;
    font-weight: 500;
  }
  .flap-data .content-box {
    position: relative;
    margin: 10px;
    border: 1px solid rgba(102, 99, 99, 0.4);
    border-radius: 8px;
    padding: 15px 20px;
  }
  .flap-data .content-box .label::before {
    content: "+";
    position: absolute;
    right: 20px;
    font-size: 1.5em;
    top: 0;
  }
}
@media (min-width: 769px) and (max-width: 1025px) {
  .faq .flap {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

#editor #editor-container {
  max-height: 500px;
  overflow-y: auto;
  font-size: 1rem;
}

#ql-editor {
  padding-bottom: 100px;
}

/* Pirvacy Policy Styling Starts Here */
.privacy-container {
  padding: 20vh 0;
  width: 70%;
  margin: auto;
}
.privacy-container h2 {
  padding-bottom: 10px;
}
.privacy-container h1 {
  padding-bottom: 10px;
}
.privacy-container p {
  padding-bottom: 15px;
}
.privacy-container ul li {
  list-style-type: disc;
}
.privacy-container a {
  color: #000;
  font-weight: 600;
  padding-left: 5px;
}
