.about-sec-1 {
    padding-bottom: 50px;
    text-align: center;
  }
  .about-sec-1 .container {
    margin-top: 12%;
  }
  .about-sec-1 .container h3 {
    font-size: 46px;
    color: #35bc98;
  }
  .about-p {
    font-family: 'Open Sans', sans-serif;
    color: rgba(44, 44, 63, 0.85);
    font-size: 16px;
    line-height: 26px;
  }
  .about-p p {
    margin-bottom: 0px;
  }
  .about-heading p {
    font-family: 'Open Sans', sans-serif;
    color: #1d1d21;
    font-size: 34px;
    line-height: 49px;
    font-weight: 700;
  }
  .about-heading span {
    font-weight: 400;
  }
  .about-sec-1 .container h3 span {
    font-weight: 700;
  }
  .about-header {
    padding-top: 20px;
    padding-bottom: 50px;
    align-items: center;
  }
  .about-header h1 {
    font-size: 55px;
    font-family: 'Montserrat', sans-serif;
    padding-bottom: 50px;
    width: 70%;
    margin: auto;
  }
  .about-left {
    text-align: left;
    z-index: 2;
    /* width: 70%; */
  }
  .about-right {
    text-align: left;
    z-index: 2;
    padding-bottom: 50px;
    /* width: 70%; */
  }
  .about-content {
    width: 75%;
    margin: auto;
  }
  .about-content img {
    height: 65%;
    width: 150%;
    transform: translateX(-110px);
    z-index: -10;
    padding-top: 35%;
    /* padding-right: 20%; */
  }
  .about-content .col-4 {
    margin: auto;
    padding: 0;
  }
  .a-sec-3 .container {
    text-align: center;
  }
  
  @media screen and (max-width: 479px) {
    .about-sec-1 .container {
      margin-top: 54%;
    }
    .about-header h1 {
      font-size: 34px;
      line-height: 40px;
      margin: 0;
      width: 100%;
    }
    .about-content img {
      display: none;
    }
    .about-left {
      width: 100%;
    }
    .about-content {
      width: 100%;
      padding-left: 30px;
      padding-right: 11px;
      position: relative;
    }
    .about-content .row {
      position: relative;
    }
    .row .about-right {
      position: relative;
    }
    /* Logos Section */
    .sec-3.a-sec-3 {
      padding-top: 0px;
    }
    .a-sec-3 {
      text-align: center;
    }
  }
  
  @media (min-width: 480px) and (max-width: 770px) {
    .about-sec-1 .container {
      margin-top: 20%;
      width: 100%;
    }
    .about-header h1 {
      font-size: 50px;
      line-height: 54px;
      margin: 0;
      width: 100%;
    }
    .about-content {
      width: 100%;
      padding-left: 30px;
      padding-right: 11px;
    }
    .about-content img {
      display: none;
    }
    .about-left {
      width: 100%;
    }
    .about-content {
      width: 100%;
      padding-left: 30px;
      padding-right: 11px;
    }
    /* Logos Section */
    .a-sec-3 {
      /* margin-top: 50%; */
      padding-bottom: 20px;
    }
  }
  
  /**************** About Us Sec-4 ********************/
  .about-sec-4 {
    padding-top: 4%;
    /* height: 75vh; */
    padding-bottom: 50px;
  }
  .about-sec-4 .container {
    width: 75%;
  }
  .about-sec-4 img {
    height: 100%;
    width: 100%;
    padding-top: 50px;
    /* transform: translateX(-70px);
      z-index: -10; */
  }
  .about-heading p {
    font-family: 'Open Sans', sans-serif;
    color: #1d1d21;
    font-size: 34px;
    line-height: 49px;
    font-weight: 700;
  }
  .about-heading span {
    font-weight: 400;
  }
  
  @media screen and (max-width: 479px) {
    /* .about-sec-4 {
        height: 95vh;
      } */
    .about-sec-4 {
      padding-bottom: 0px;
    }
    .about-right {
      width: 100%;
      padding-left: 35px;
      padding-right: 11px;
      padding-bottom: 50px;
    }
    .about-sec-4 .container {
      width: 100%;
    }
  }
  
  @media (min-width: 480px) and (max-width: 770px) {
    .about-right {
      width: 100%;
      padding-left: 11px;
      padding-right: 11px;
      transform: translateX(0%) !important;
    }
    .about-sec-4 .container {
      width: 100%;
    }
  }
  
  /**************** About Us Sec-5 ********************/
  /* .about-sec-5 {
    align-items: center;
    padding-bottom: 50px;
  }
  .about-sec-5 .heading {
    text-align: center;
  }
  .sec-5-blog a {
    text-decoration: none;
    color: #2d2d31;
  }
  .sec-5-blog a:hover {
    color: #000;
  }
  .about-sec-5 .heading {
    padding-bottom: 50px;
  }
  .about-sec-5 .container {
    width: 73%;
    padding-right: 30px;
    padding-left: 30px;
  }
  .sec-5-blog {
    padding: 0 10px;
  }
  .sec-5-blog a h4 {
    align-items: left;
    padding-top: 10px;
  }
  .about-sec-5 img {
    width: 100%;
    height: 230px;
    box-shadow: 0 4px 19px -8px rgb(0 0 0 / 30%);
  }
  @media screen and (max-width: 479px) {
    .about-sec-5 .container {
      width: 100%;
    }
    .about-sec-5 .container .row {
      display: flex;
      flex-direction: column;
    }
    .sec-5-blog {
      width: 100%;
    }
  }
  @media (min-width: 480px) and (max-width: 770px) {
    .about-sec-5 {
      padding-top: 20%;
      padding-bottom: 20%;
    }
    .about-sec-5 .container {
      width: 100%;
    }
    .about-sec-5 .container .row {
      display: flex;
      flex-direction: column;
    }
    .sec-5-blog {
      width: 100%;
    }
  } */
  /************************************************* AboutUS Section Ends Here ************************************************/
  